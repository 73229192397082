@charset 'UTF-8';
@import "/Users/thuy/Website/vhost/breeding/src/scss/utility/_media-queries.scss";
@import "/Users/thuy/Website/vhost/breeding/src/scss/utility/_mixin.scss";



/* Common */
.c-wcm01 {
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  @include PC {
    padding: 0 20px;
  }
  @include SP {
    padding: 0 15px;
  }
}
.c-wrap01 {
  @include PC {
    @include flexbox;
  }
}
.c-wrap02 {
  @include PC {
    @include flexbox;
    @include flex-wrap;
  }
}
.c-wrap03 {
  @include PC {
    @include flexbox;
    @include justify-content(space-between);
  }
}
.c-wrap04 {
  @include flexbox;
  @include flex-wrap;
}
.c-wrap05 {
  @include flexbox;
}
.c-ttl01 {
  @include ffPlay;
  @include font(46,'',0,700);
  @include SP {
    @include font(32);
    text-align: center;
  }
}
.c-ttl02 {
  .ttl-en {
    display: block;
    @include ffPlay;
    @include font(30,'',0,700);
    @include PC {
      margin-bottom: 16px;
    }
    @include SP {
      margin-bottom: 8px;
      @include font(24);
    }
  }
  .ttl-jp {
    display: block;
    @include ffShuei;
    @include font(16,'',100);
    @include SP {
      @include font(14);
    }
  }
}
.c-img__box {
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 71.6%;
  }
  img {
    transition: 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
  }
}
/* Common */
.main-visual {
  position: relative;
  z-index: 399;
  overflow: hidden;
  @include PC {
    height: 100vh;
    min-height: 500px;
  }
  @include SP {
    height: 100vh;
    min-height: 436px;
  }
  .main-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      // right: 0;
      // bottom: 0;
      width: 100%;
      height: 100%;
      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: 100%;
        @include SP {
          min-width: calc(100% + 104px);
          object-position: bottom 0 left -104px;
        }
      }
    }
    .slick-list, .slick-slide, .slick-track {
      height: 100%;
    }
    .slick-slide {
      > div {
        height: 100%;
        .item-img {
          position: relative;
          height: 100%;
        }
      }
    }
  }
  .mv-inner {
    position: absolute;
    z-index: 49;
    opacity: 0;
    transition: transform 0.4s ease 0.2s, opacity 0.4s ease 0.2s;
    transition-delay: 0s;
    text-align: center;
    @include PC {
      top: calc(50% - 127px);
      left: calc(50% - 131px);
    }
    @include SP {
      width: 100%;
      top: calc(50% - 78px);
      left: calc(50% - 87px);
      max-width: 175px;
    }
    .mv-logo {
      @include PC {
        margin-bottom: 39px;
      }
      @include SP {
        width: 78%;
        margin: 0 auto 44px;
      }
    }
    .mv-ttl {
      border-top: solid 1px #fff;
      position: relative;
      @include PC {
        padding: 9px 0 24px;
      }
      @include SP {
        padding: 4px 0 23px;
      }
      .txt {
        @include ffPlay;
        @include font(15,'',50,600);
        color: #fff;
        @include SP {
          @include font(10,'');
        }
      }
      .arr-about {
        display: block;
        position: absolute;
        bottom: 0;
        @include bgimg ($img_url + "top/mv_arr.svg", no-repeat, center, transparent, 100% 100%);
        animation: bounce 2s infinite;
        @include PC {
          right: calc(50% - 12px);
          width: 24px;
          height: 14px;
        }
        @include SP {
          right: calc(50% - 13px);
          width: 24px;
          height: 13px;
        }
      }
    }
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
.is-loaded {
  .main-visual {
    .mv-inner {
      opacity: 1;
      @include transform(translateY(0));
    }
  }
}
/* About */
.sec-about {
  @include PC {
    margin: 80px 0 39px;
  }
  @include SP {
    margin: 64px 0 78px;
  }
  .col-content {
    @include PC {
      width: 56.31%;
      margin-right: 4.47%;
      padding-top: 19px;
    }
    @include SP {
      padding: 0 4px 0 5px;
      margin-bottom: 64px;
    }
    .ttl01 {
      @include ffPlay;
      @include font(46,'',0,700);
      @include PC {
        margin-bottom: 38px;
      }
      @include SP {
        margin-bottom: 37px;
        @include font(32);
        text-align: center;
      }
    }
    .txt-box {
      @include PC {
        margin-top: 38px;
      }
      @include SP {
        margin-top: 37px;
      }
      .txt {
        @include ffShuei;
        @include font(12,24,100);
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

  }
  .col-img {
    flex: 1;
    overflow: hidden;
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 100%;
      @include PC {
        padding-top: 102.7%;
      }
      @include SP {
        padding-top: 103%;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      z-index: 1;
    }
  }
}
/* About */
/* News */
.sec-new {
  @include PC {
    margin-bottom: 163px;
  }
  @include SP {
    margin-bottom: 135px;
  }
  .wrap-box {
    @include PC {
      margin: 64px 0 50px;
    }
    @include SP {
      width: 100%;
      max-width: 264px;
      margin: 42px auto 48px;
    }
  }
  .new-item {
    @include PC {
      width: 29.33%;
      margin-right: 6.005%;
    }
    @include SP {
      margin-bottom: 38px;
    }
    &:nth-child(3n), &:last-child {
      @include PC {
        margin-right: 0;
      }
      @include SP {
        margin-bottom: 0;

      }
    }
    .item-inner {
      @include HOVER {
        &:hover {
          opacity: 1;
          .c-img__box {
            img {
              transform: scale(1.2);
              transition: 0.3s;
            }
          }
        }
      }
    }
    .item-txt {
      margin-top: 9px;
      .txt {
        @include ffShuei;
        @include font(12,18,0);
        margin-bottom: 8px;
      }
      .txt-more {
        @include ffPlay;
        @include font(15,'',0,700);
        padding-left: 13px;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          @include bgimg ($img_url + "top/more_arr.svg", no-repeat, center, transparent, 100% 100%);
          width: 7px;
          height: 12px;
          top: calc(50% - 6px);
          left: 0;
        }
      }
    }
  }
  .link-page-list {
    padding-left: 26px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      @include bgimg ($img_url + "top/all_arr.svg", no-repeat, center, transparent, 100% 100%);
      width: 13px;
      height: 24px;
      top: calc(50% - 16px);
      left: 0;
    }
    .txt-en {
      @include ffPlay;
      @include font(30,'',0,700);
      margin-right: 8px;
      @include SP {
        @include font(24);
      }
    }
    .txt-jp {
      @include ffShuei;
      @include font(12,'',100);
      position: relative;
      bottom: 1px;
      @include SP {
        @include ANDR {
          @include font(10);
        }
      }
    }
  }
}
/* News */
/* Horse */
.sec-horses {
  @include PC {
    margin-bottom: 206px;
  }
  @include SP {
    margin-bottom: 89px;
  }
  .c-ttl02 {
    @include PC {
      margin-top: 75px;
    }
    @include SP {
      margin-top: 57px;
    }
  }
  .wrap-box {
    @include PC {
      margin-top: -4px;
    }
    @include SP {
      margin-top: -20px;
    }
    .item-inner {
      @include HOVER {
        &:hover {
          opacity: 1;
          .c-img__box {
            img {
              transform: scale(1.2);
              transition: 0.3s;
            }
          }
        }
      }
    }
  }
  .horses-item {
    @include PC {
      width: 29.33%;
      margin: 38px 6.005% 0 0;
    }
    @include SP {
      width: 46.96%;
      margin: 48px 6.08% 0 0;
    }
    &:nth-child(3n), &:last-child {
      @include PC {
        margin-right: 0;
      }
    }
    &:nth-child(2n), &:last-child {
      @include SP {
        margin-right: 0;
      }
    }
  }
  .box-txt {
    margin-top: 6px;
    .txt {
      @include ffShuei;
      @include font(12,24,100);
      flex: 1;
      @include SP {
        margin-bottom:-5px;
      }
    }
    .txt-more {
      @include ffPlay;
      @include font(15,'',0,700);
      position: relative;
      padding-left: 13px;
      @include PC {
        margin-left: 10px;
      }
      @include SP {
        @include font(13);
      }
      &:before {
        content: "";
        position: absolute;
        @include bgimg ($img_url + "top/more_arr.svg", no-repeat, center, transparent, 100% 100%);
        width: 7px;
        height: 12px;
        top: 7px;
        left: 0;
      }
    }
  }
  .wrap-btn {
    @include PC {
      margin-top: 45px;
    }
    @include SP {
      margin-top: 40px;
    }
    .button-item {
      @include PC {
        width: 20%;
        margin: 20px 6.666% 0 0;
      }
      @include SP {
        margin-bottom: 30px;
      }
      &:nth-child(4n) {
        @include PC {
          margin-right: 0;
        }
      }
      &:last-child {
        @include PC {
          margin-right: 0;
        }
        @include SP {
          margin-bottom: 0;
        }
      }
    }
    .item-inner {
      border: solid 1px $color_body;
      display: block;
      text-align: center;
      @include PC {
        padding: 36px 10px 29px;
      }
      @include SP {
        padding: 16px 10px 11px;
      }
      .txt-en {
        display: inline-block;
        @include ffPlay;
        @include font(30,'',0,700);
        position: relative;
        @include PC {
          padding-right: 20px;
          @include screen (768px, 850px) {
            @include font(26);
          }
        }
        @include SP {
          padding-right: 15px;
          @include font(24);
        }
        &:before {
          content: "";
          position: absolute;
          @include bgimg ($img_url + "top/all_arr.svg", no-repeat, center, transparent, 100% 100%);
          @include PC {
            width: 13px;
            height: 24px;
            top: calc(50% - 13px);
            right: 0;
          }
          @include SP {
            width: 9px;
            height: 16px;
            top: calc(50% - 8px);
            right: 0;
          }
        }
        &:first-letter {
          text-transform: uppercase;
        }
      }
      .txt-jp {
        display: block;
        @include ffShuei;
        @include font(12,'',100);
        @include PC {
          margin-top: 2px;
        }
        @include SP {
          margin-top: -1px;
        }
      }
    }
  }
}
/* Horse */
/* Commpany */
.sec-company {
  @include PC {
    margin-bottom: 189px;
  }
  @include SP {
    margin-bottom: 131px;
  }
  .col01 {
    @include PC {
      width: 218px;
      margin-right: 7.78%;
    }
    @include SP {
      width: 100%;
      max-width: 218px;
      margin-bottom: 65px;
    }
    &.c-img__box {
      &:before {
        padding-top: 136.4%;
      }
    }
  }
  .col02 {
    flex: 1;
    padding-top: 2px;
    .c-ttl01 {
      text-align: left;
    }
    .company-table {
      @include PC {
        margin-top: 41px;
      }
      @include SP {
        margin-top: 37px;
      }
    }
    .table-row {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      .row-head {
        width: 66px;
        margin-right: 19px;
        @include ffShuei;
        @include font(12,24,100);
      }
      .row-ctn {
        flex: 1;
        @include ffShuei;
        @include font(12,24,100);
        &.spec {
          @include SP {
            @include font('',18);
          }
        }
      }
    }
  }
}
/* Commpany */
/* Contact */
.sec-contact {
  @include PC {
    margin-bottom: 110px;
  }
  @include SP {
    margin-bottom: 46px;
  }
  .c-wcm01 {
    position: relative;
    @include PC {
      padding-left: 60px;
    }
  }
  .txt-contact {
    @include ffShuei;
    @include font(12,24,100);
    @include PC {
      margin-top: 42px;
    }
    @include SP {
      margin-top: 36px;
    }
  }
  .contact-img {
    width: 100%;
    max-width: 278px;
    @include PC {
      position: absolute;
      top: 92px;
      right: 20px;
    }
    @include SP {
      margin: 94px 0 0 auto;
    }
    &.c-img__box {
      &:before {
        padding-top: 87.05%;
      }
    }
  }
  .c-ttl01 {
    @include SP {
      text-align: left;
    }
  }
  .inner {
    @include SP {
      padding: 0;
    }
  }
}
.sec-contact.page-form {
  .tableContact {
    border: none;
    margin: 34px 0 28px;
    @include PC {
      width: 59%;
    }
    .row-head {
      background: none;
      border: none;
      padding: 0;
      width: 67px;
      @include ffShuei;
      @include font(12,'',100);
      display: inline-block;
      padding: 0;
    }
    .row-input {
      border: none;
      padding: 0;
      display: inline-block;
      flex: 1;
      max-width: 289px;
      position: relative;
    }
    .table-row.field-name {
      margin-bottom: 23px;
      @include flexbox;
      @include align-items(flex-end);
      .row-head {
        @include PC {
          margin-bottom: -6px;
        }
        @include SP {
          margin-bottom: -7px;
        }
        // .is-ipad &, .is-ipad109 &, .is-ipad97 &, .ipad & {
        //   margin-bottom: -6px;
        // }
      }
      input {
        @include ffShuei;
        @include font(12,25,100);
        border: solid $color_body;
        border-width: 0 0 1px 0;
        padding: 10px 0 10px;
        width: 100%;
        border-radius: 0;
      }
    }
    .table-row.field-email {
      margin-bottom: 42px;
      @include flexbox;
      @include align-items(flex-end);
      .row-head {
        @include PC {
          margin-bottom: -6px;
        }
        @include SP {
          margin-bottom: -7px;
        }
      }
      input {
        @include ffShuei;
        @include font(12,25,100);
        border: solid $color_body;
        border-width: 0 0 1px 0;
        padding: 10px 0 10px;
        width: 100%;
        border-radius: 0;
      }
    }
    .table-row.field-content {
      .row-head {
        width: 100%;
        display: block;
        @include PC {
          margin-bottom: 14px;
        }
        @include SP {
          margin-bottom: 15px;
        }
      }
      .row-input {
        width: 100%;
        max-width: 488px;
      }
      textarea {
        @include ffShuei;
        @include font(12,25,100);
        border: solid 1px #3E3E3E;
        width: 100%;
        height: 191px;
        border-radius: 0;
      }
    }
    input::placeholder {
      @include ffShuei;
      @include font(12,24,100);
      color: #C4C4C4;
    }
    .error {
      position: absolute;
      bottom: -26;
      left: 0;
      margin-top: 5px;
    }
  }
  .error {
    margin-top: 5px;
  }
  .taC {
    .check-confirm {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 28px;
        height: 28px;
        border: solid 1px #3E3E3E;
        top: 1px;
        z-index: -1;
      }
    }
    input {
      width: 28px;
      height: 28px;
      position: absolute;
      top: 1px;
      opacity: 0;
    }
    .fz14 {
      @include ffShuei;
      @include font(12,24,100);
      margin-left: 42px;
      @include SP {
        @include font('','',0);
      }
    }
    .mwform-checkbox-field-text {
      @include ffShuei;
      @include font(12,24,100);
      margin-left: 42px;
      @include SP {
        @include font('','',0);
      }
    }
    .checkmark {
      width: 28px;
      height: 28px;
      border: solid 1px #3E3E3E;
      display: block;
      position: absolute;
      top: 1px;
      z-index: -1;
      color: transparent;
      &:before {
        content: "";
        position: absolute;
        width: 16px;
        height: 8px;
        border: solid #3E3E3E;
        border-width: 0 0 2px 2px;
        transform: rotate(-45deg);
        display: none;
        top: 6px;
        left: 6px;
      }
      &.check {
        &:before {
          display: block;
        }
      }
    }
  }
  .contact-law {
    border: solid 1px #C1C1C1;
    height: 136px;
    @include PC {
      margin: 16px -15px 0 0 ;
      padding: 11px 9px 5px;
    }
    @include SP {
      margin-top: 15px;
      padding: 11px 9px;
    }
    .txt {
      @include ffYG;
      @include font(10,14,0,700);
      color: #818181;
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }
      .link {
        color: #1967d2;
        text-decoration: underline;
      }
    }
    .contact-law__inner {
      height: 100%;
      overflow: auto;
    }
  }
  .t30b20 {
    margin-top: 36px;
    .btnConfirm {
      background: $color_body;
      width: 100%;
      max-width: 230px;
      padding: 6px 18px 6px 10px;
      text-align: left;
      position: relative;
      border: none;
      @include ffShuei;
      @include font(14,'',100);
      color: #fff;
      transition: 0.3s ease;
      &:after {
        content: "";
        position: absolute;
        @include bgimg ($img_url + "top/submit_arr.svg", no-repeat, center, transparent, 100% 100%);
        width: 9px;
        height: 14px;
        top: calc(50% - 7px);
        right: 9px;
      }
      @include HOVER {
        &:hover {
          opacity: 0.6;
          transition: 0.3s ease;
        }
      }
    }
  }
  .formError {
    top: 0!important;
    right: 0!important;
    left: auto!important;
  }
}
/* Contact */
/* Simple bar */
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
}

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.simplebar-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: scrollbar !important;
}

.simplebar-dummy-scrollbar-size > div {
  width: 200%;
  height: 200%;
  margin: 10px 0;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
/* Simple bar */
.sec-contact.page-form {
  .check-confirm br {
    display:none!important;
  }
}
.top {
  .btnSend {
    display: none;
  }
}